<template>
    <div>
        <!-- Mobile -->
        <v-container class="mt-2 hidden-md-and-up mx-auto">
            <v-row v-if="prayers.length">
                <v-col
                    v-for="prayer in prayers"
                    :key="prayer.id"
                    cols="6">
                    <Prayer :prayer="prayer"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <div class="text-center mx-auto">
                אין פריטים שמורים
                </div>
            </v-row>
        </v-container>
        <!-- Desktop -->
        <v-container class="mt-2 hidden-sm-and-down mx-auto">
            <v-row v-if="prayers.length">
                <v-col
                    v-for="prayer in prayers"
                    :key="prayer.id"
                    cols="3">
                    <Prayer :prayer="prayer"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <div class="text-center mx-auto">
                אין פריטים שמורים
                </div>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import Prayer from '@/components/Cards/Prayer.vue'
import { mapGetters } from 'vuex'

export default {
    data() {
      return {
        pra: []
      }
    },
    methods: {
        loadPrayers() {
            try {
                // let loadedPrayers =  require('@/assets/prayers/prayers.json')
                // this.pra = loadedPrayers.prayers

                fetch('https://amisraelive.com/prayers/prayers.json')
                    .then(response => response.json())
                    .then(data => {
                        this.pra = data.prayers
                    })
                    .catch(() => {
                        let loadedPrayers =  require('@/assets/prayers/prayers.json')
                        this.pra = loadedPrayers.prayers
                    })

            } catch(e) {
                console.log('error loading prayers', e)
            }
        }
    },
    computed: {
        ...mapGetters('storeApp', ['favorites']),
        prayers: {
            get() {
                if(this.$route.name == 'Favorites') {
                    return this.pra.filter(prayer => this.favorites.includes(prayer.id))
                } else {
                    return this.pra
                }

            }
        }
    },
    components: {
        Prayer
    },
    created() {
      this.loadPrayers()
    }
}

</script>

