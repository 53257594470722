<template>
    <div>
        <v-card>
            <v-img
              :src="prayer.img"
              :lazy-src="prayer.img"
              class="white--text align-end pointer"
              @click="selectedPrayer(prayer.id)"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="130px">
              <v-card-title class="subtitle-2" v-text="prayer.name"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn 
                icon
                @click="sharePress(prayer.id)">
                <v-icon color="primary">mdi-share-variant</v-icon>
              </v-btn>

              <v-btn 
                icon
                @click="favPress(prayer.id)">
                <v-icon v-if="favorites.includes(prayer.id)" color="fav">mdi-star</v-icon>
                <v-icon v-else color="primary">mdi-star-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['prayer'],
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('storeApp', ['favorites'])
    },
    methods: {
        ...mapActions('storeApp', ['addFav', 'removeFav']),
        ...mapActions('storeShare', ['setShareVisible', 'setShare']),
        selectedPrayer(id) {
            window.open('/prayer/'+id, "_self")
        },
        favPress(id) {
            if(this.favorites.includes(id)) {
                this.removeFav(id)
            } else {
                this.addFav(id)
            }
        },
        sharePress(id) {
             let share = {
                url: 'https://amisraelive.com/prayer/' + id,
                title: this.prayer.name,
                description: 'עם ישראל חי'
            }
            this.setShare(share)
            this.setShareVisible(true)
        }
    }
}

</script>

<style scoped>

.pointer {
    cursor: pointer;
}


</style>